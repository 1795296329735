@import url(themeSettings.css);

.ant-message {
    top: 60px !important;
    z-index: 111111;
}

.ant-message-notice-content {
    padding: 0 !important;
    font-size: 16px;
}

.ant-message-custom-content span {
    top: 3px;
    position: relative;
}

.ant-message-success, .ant-message-loading {
    padding: 5px 10px !important;
    border-radius: 3px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
}

.ant-message-success {
    padding: 0 10px 0 0 !important;
    background: #d4edda !important;
}

.ant-message-error {
    background: #f8d7da !important;
    padding: 0 10px 0 0 !important;
}

.ant-message .anticon {
    border-radius: 3px;
}

.ant-message-notice-content span.anticon.anticon-check-circle {
    padding: 10px;
    color: #fff !important;
    background: #28a745;
    top: 0 !important;
}

.ant-message-notice-content span.anticon.anticon-close-circle {
    padding: 10px;
    color: #fff !important;
    background: #ff4d4f;
    top: 0 !important;
}

.ant-collapse.border-danger {
    border: 1px solid #dc3545 !important;
    background-color: #f8d7da;
}

.ant-collapse-content > .ant-collapse-content-box, .ant-select:not(.ant-select-customize-input):not(.ant-select-disabled), .ant-select:not(.ant-select-customize-input):not(.ant-select-disabled) .ant-select-selector {
    background: var(--bg-color);
}

.ant-select-selector, .ant-input {
    background: var(--bg-color);
    color: var(--dark-text);
}

.ant-empty-description {
    color: var(--dark-text);
}

.ant-select {
    color: var(--dark-text);
}

.ant-switch-checked {
    background-color: var(--primary-pink);
}

.dark-theme .ant-upload.ant-upload-drag {
    background: #292929;
    color: #fff;
}

.ant-select-selection-item-remove, .ant-select-selection-item-content, .ant-select-multiple .ant-select-selection-item-remove {
    color: #000;
}

/*.rc-virtual-list-holder {
    max-height: 225px !important;
}*/

.grow .ant-transfer-list {
    flex-grow: 1;
}

.ant-transfer-list-content-item-checked, .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
    background-color: var(--light-pink-to-greyscale);
}

.ant-checkbox-checked::after {
    border: 1px solid var(--primary-pink);
}

.ant-checkbox-checked .ant-checkbox-inner, .ant-btn-primary {
    background-color: var(--primary-pink);
    border-color: var(--primary-pink);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: var(--primary-pink);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary-pink);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff;
    background: hotpink;
    border-color: hotpink;
}

.dark-theme .ant-transfer-list-content-item {
    color: #fff;
}

.ant-transfer .anticon {
    vertical-align: unset !important;
}

.h-300-list .ant-spin-nested-loading {
    height: 261px;
    overflow: auto;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after, .ant-select-tree-checkbox-checked::after, .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: var(--primary-pink) !important;
    border-color: var(--primary-pink) !important;
}

.ant-modal-mask, .ant-modal-wrap {
    z-index: 111111;
}

/*.rc-virtual-list-scrollbar {
    display: block !important;
}*/

/*.rc-virtual-list-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

    .rc-virtual-list-scrollbar::-webkit-scrollbar:vertical {
        width: 11px;
    }

    .rc-virtual-list-scrollbar::-webkit-scrollbar:horizontal {
        height: 11px;
    }

.rc-virtual-list-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid black;
    background-color: rgba(0, 0, 0, .5);
}*/

.rc-virtual-list-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 10px !important;
}

.rc-virtual-list-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: rgba(0,0,0,.3) !important;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5) !important;
}

.ant-table-tbody > tr.ant-table-row.user-inactive:hover > td {
    background-color: var(--warning-color);
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: var(--light-grey-hover) !important;
}

.ant-table tr:not(.user-inactive), .ant-table th {
    background-color: var(--bg-color) !important;
}

.ant-table-thead > tr > th {
    color: var(--text-color);
}

.modal-content {
    background: var(--bg-color) !important;
}

.ant-select-item-group {
    color: #F30079 !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #f5f5f5 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary-pink) !important;
}