.editor-textarea {
    min-height: 400px;
    margin: 10px;
    border-top: 1px solid grey;
    padding: 0 10px;
}

.DraftEditor-root {
    height: 400px;
}

.height-60vh {
    height: 60vh !important;
}

.tox-tinymce--disabled .tox-editor-header {
    display: none !important;
}