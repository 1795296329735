@import url(themeSettings.css);

h1, h2, h3, h4, h5, h6 {
    color: var(--text-color) !important;
}

.page-content {
    color: var(--text-color) !important;
    background: var(--bg-color) !important;
    padding: 40px .5rem !important;
}

.panel-hdr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--bg-color);
    min-height: 3rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px 4px 0 0;
}

.nav-function-top .page-header {
    margin-top: 0;
    height: 4.125rem;
    background-color: var(--light-grey);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.top-nav-item-desc {
    letter-spacing: .05rem;
    text-transform: uppercase;
    font-weight: 400;
    font-family: proxima-nova,sans-serif;
    color: var(--dark-text);
}

.text-danger {
    color: var(--text-danger) !important;
}

.slide-pane__content {
    background: var(--bg-color);
}

.form-label {
    color: var(--dark-text)
}

.ant-select-item.ant-select-item-option {
    background-color: var(--bg-color);
    color: var(--text-color);
}

.slide-pane.dark-theme {
    background: #292929;
}

    .slide-pane.dark-theme .slide-pane__header, .slide-pane.dark-theme .slide-pane__title {
        background: #292929;
        color: #fff !important;
    }

    .slide-pane.dark-theme .slide-pane__content, .slide-pane.dark-theme .slide-pane__content .card {
        background: #292929;
        color: #fff;
    }

        .slide-pane.dark-theme .slide-pane__content .card {
            border: 1px solid rgba(255, 255, 255, 0.125);
            color: #fff;
        }

        .slide-pane.dark-theme .slide-pane__content .form-control-custom, .modal-dialog.dark-theme .modal-content .form-control-custom, .dark-theme .form-control-custom, .dark-theme .form-control, .dark-theme .modal-dialog .modal-content, .dark-theme option, 
            .dark-theme .custom-fancy-dropdown div {
            color: #fff;
            background-color: #292929;
            background-image: linear-gradient(to bottom, #292929 0%, #292929 100%);
        }

            .slide-pane.dark-theme .slide-pane__content .form-control-custom:focus, .modal-dialog.dark-theme .modal-content .form-control-custom:focus, .dark-theme .form-control-custom:focus, .dark-theme .form-control:focus, .dark-theme .modal-dialog .modal-content:focus,
                .dark-theme .custom-fancy-dropdown div, .dark-theme .ant-select-item {
                color: #fff !important;
            }

        .slide-pane.dark-theme .slide-pane__content .form-label, .dark-theme .modal-content .form-label, .dark-theme .modal-footer .btn-outline-default, .dark-theme .modal-header h1 {
            color: #fff !important;
        }

.modal-dialog.dark-theme .modal-content {
    background-color: #292929 !important;
}

    .modal-dialog.dark-theme .modal-content h1, .modal-dialog.dark-theme .modal-content .form-label, .modal-dialog.dark-theme .modal-content .btn-outline-default, .slide-pane.dark-theme .slide-pane__content .btn-outline-default  {
        color: #fff !important;
    }

.custom-dropdown-menu {
    background-color: var(--white-to-grey) !important;
    color: var(--dark-text);
}

    .custom-dropdown-menu div {
        background-color: var(--white-to-grey) !important;
        color: var(--dark-text) !important;
    }

        .custom-dropdown-menu .hoverable:hover div {
            background-color: #ededed !important;
            color: #000 !important;
        }

        .custom-dropdown-menu div.color-pink {
            color: var(--primary-pink) !important;
        }

.dark-mode .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, .7);
}

.slide-pane__overlay {
    z-index: 1009 !important;
}

#am-confirm-spanner > div {
    width: 50% !important;
}

.no-y-overflow {
    overflow-y: unset !important;
}

.am-message-container {
    z-index: 1050 !important;
}

.ant-pagination-total-text, .ant-pagination-item-ellipsis, .ant-select-arrow {
    color: var(--dark-text) !important;
}

.ant-pagination-next > button, .ant-pagination-prev > button {
    color: var(--primary-pink) !important;
}