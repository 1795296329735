@import url(themeSettings.css);

.rbc-today {
    background-color: #ffe6ea !important;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    background-color: transparent !important;
    color: #F30079 !important;
}

    .rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
        outline: 5px auto #F30079 !important;
        background-color: #ffe6ea !important;
        color: #F30079;
    }

.rbc-off-range-bg {
    background-color: var(--light-grey);
}

.rbc-toolbar button {
    color: var(--dark-text);
}

    /*.rbc-toolbar button:active, .rbc-toolbar button.rbc-active,
    .rbc-current > a, .rbc-current > a:visited {
        color: var(--white-to-grey) !important;
    }*/