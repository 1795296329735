.margin-25-10 {
    margin: 25px 10px;
}

.margin-10-0 {
    margin: 10px 0;
}

.margin-0-10 {
    margin: 0 10px;
}

.padding-0-10 {
    padding: 0 10px;
}

.padding-25-10 {
    padding: 25px 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-left-15 {
    margin-left: 15px;
}

.padding-right-15 {
    padding-right: 15px;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-left-45 {
    padding-left: 45px;
}

.line-height-1-5 {
    line-height: 1.5;
}

.no-bottom-margin {
    margin-bottom: unset;
}

.xs-margin-top{
    margin-top: .3rem;
}

.padding-top-2rem{
    padding-top: 2rem;
}

.padding-left-none {
    padding-left: 0;
}

.margin-left-none {
    margin-left: 0;
}

.margin-top-20{
    margin-top: 20px;
}

.padding-top-15 {
    padding-top: 15px;
}

.margin-bottom-15rem {
    margin-bottom: 1.5rem;
}